@import url("https://use.typekit.net/osl7hkc.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
p,
li {
  font-family: degular, sans-serif;
  font-weight: 200;
  font-style: normal;
  letter-spacing: 0.1rem;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: "meno-banner", serif;
  font-weight: 300;
  font-style: normal;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 26px;
}

.button-heading-text {
  font-weight: 700;
}

li,
a {
  list-style: none;
  text-decoration: none;
}

a {
  color: #e8e9eb;
}

.button {
  height: auto;
  padding: 1rem 2rem;
  background-color: transparent;
  color: #2d3d2e;
  border: solid;
  border-color: #2d3d2e;
  border-radius: 1rem;
  border-width: 0.2rem;
  text-align: left;
  transition: 0.6s ease-in-out;
}

.button:hover {
  cursor: pointer;
  background-color: #2d3d2e;
  color: #e3ddd8;
  transition: 0.6s ease-in-out;
}
