.header {
  background-color: #2d3d2e;
  position: relative;
  z-index: 1;
}

.nav-link {
  font-family: "meno-banner", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  color: #e3ddd8;
}

.nav-bar {
  top: 0px;
  position: fixed;
  transition-duration: 0.5s;
  width: 100%;
  padding: 0 2rem;
}

.nav-background {
  background-color: #2d3d2e;
}

.nav--hidden {
  position: fixed;
  top: -8rem;
  transition: 0.5s;
}

.nav-logo-link {
  display: flex;
  justify-content: left;
}

.nav-brand {
  display: flex;
  justify-self: left;
  padding: 2rem 0;
}

.nav-brand-padding {
  padding-bottom: 2rem;
}

.nav-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.hamburger-grid-item {
  display: flex;
  align-items: center;
  justify-content: right;
}

.hamburger {
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 0.25em;
  transition: 0.3s ease;
}

.hamburger:hover {
  cursor: pointer;
}

.bar1,
.bar2 {
  background-color: #e3ddd8;
  border-radius: 1em;
  width: 2rem;
  height: 0.25rem;
  position: absolute;
  display: block;
  transform: rotate(0deg);
  transition: all 0.4s;
}

.bar1 {
  top: 3.5rem;
}

.bar2 {
  top: 4.5rem;
}

.bar1.spin {
  top: 4rem;
  transform: rotate(135deg);
}

.bar2.spin {
  top: 4rem;
  transform: rotate(225deg);
}

.spin {
  background-color: #e3ddd8;
}

.nav-link-wrapper:after {
  content: "";
  position: absolute;
  left: -10%;
  width: 120%;
  height: 100%;
  margin: 0 auto;
  background: #2d3d2e;
}

.nav-overlay {
  position: fixed;
  background: #2d3d2e;
  z-index: -2;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding: 4rem;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;
}

ul.nav-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul.nav-menu li a {
  font-size: 2rem;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  top: 0;
}

.nav-item {
  padding-bottom: 0.5rem;
}

@media only screen and (min-width: 50rem) {
  ul.nav-menu li a {
    font-size: 4.5rem;
  }

  .nav-bar {
    padding: 0 5rem;
  }
}

@media only screen and (min-width: 70rem) {
  .nav-bar {
    padding: 0 4.375rem;
  }
}
