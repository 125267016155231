.home {
  background-color: #e3ddd8;
  padding-top: 9rem;
}

.home-body-text,
.home-large-heading,
.heading-2 {
  color: #2d3d2e;
}

.home-large-heading {
  font-size: 3rem;
  line-height: 3.7rem;
}

/* .home-text-align,
.text-feature-text {
  text-align: center;
} */

.home-image-margin {
  margin-bottom: 4rem;
}

.home-section-padding {
  padding-bottom: 2rem;
}

.text-feature-text {
  padding-bottom: 4rem;
}

.home-text-padding {
  padding-bottom: 0.5rem;
}

.padding-top {
  padding-top: 2rem;
}

.image-border {
  border-radius: 2rem;
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 1rem;
  padding: 2rem 2rem 4rem;
}

.home-image-slider {
  margin: 0 auto;
  padding-bottom: 2rem;
}

.home-intro-section {
  grid-template-rows: repeat(7, auto);
}

.text-feature {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
}

.home-intro-feature-image {
  grid-column: 1 / 5;
  grid-row: 2 / 3;
}

.home-intro-text {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
}

.home-intro-contact-button-link {
  grid-column: 1 / 5;
  grid-row: 4 / 5;
}

.home-about-me {
  grid-template-rows: repeat(5, auto);
}

.home-about-me-image {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
}

.home-about-me-content {
  grid-column: 1 / 5;
  grid-row: 2 / 3;
}

.home-about-me-button-link {
  grid-column: 1 / 5;
  grid-row: 4 / 5;
}

.home-gallery {
  grid-template-rows: repeat(3, auto);
}

.home-gallery-text {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
}

.home-image-slider {
  grid-column: 1 / 5;
  grid-row: 2 / 3;
}

.home-gallery-button-link {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
}

@media only screen and (min-width: 50rem) {
  .home-image-margin {
    margin-bottom: 0rem;
  }

  .home {
    padding-top: 6rem;
  }

  .padding-top {
    padding-top: 0rem;
  }

  .home-text-align {
    text-align: left;
  }

  .home-intro-text,
  .home-about-me-content,
  .home-button-align {
    align-self: end;
  }

  .home-grid {
    grid-template-columns: repeat(8, 1fr);
    padding: 4rem 2rem;
  }

  .home-intro {
    grid-template-rows: repeat(6, auto);
  }

  .home-large-heading {
    font-size: 4.5rem;
    letter-spacing: -0.1rem;
    line-height: 5rem;
  }

  .home-image-slider {
    max-width: 100%;
    padding-bottom: 0;
    margin: 0;
  }

  .text-feature {
    grid-column: 1 / 9;
    grid-row: 1 / 2;
  }

  .home-intro-feature-image {
    grid-column: 1 / 5;
    grid-row: 2 / 4;
  }

  .home-intro-text {
    grid-column: 6 / 9;
    grid-row: 2 / 3;
  }

  .home-intro-contact-button-link {
    grid-column: 6 / 9;
    grid-row: 3 / 4;
  }

  .home-about-me {
    grid-template-rows: repeat(6, auto);
  }

  .home-about-me-image {
    grid-column: 5 / 9;
    grid-row: 1 / 4;
    min-width: 20rem;
  }

  .home-about-me-content {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .home-about-me-button-link {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }

  .home-gallery {
    grid-template-rows: repeat(4, auto);
  }

  .home-image-slider {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
  }

  .home-gallery-text {
    grid-column: 1 / 9;
    grid-row: 1 / 2;
  }

  .home-gallery-button-link {
    grid-column: 6 / 9;
    grid-row: 3 / 4;
  }
}

@media only screen and (min-width: 70rem) {
  .home-grid {
    grid-template-columns: repeat(12, 1fr);
    padding: 4rem 4.375rem;
  }

  .home-large-heading {
    font-size: 7rem;
    letter-spacing: -0.2rem;
    line-height: 7.5rem;
  }

  .home-body-text {
    font-size: 1.5rem;
  }

  .button-heading-text {
    font-size: 1.8rem;
  }

  .text-feature {
    grid-column: 1 / 13;
    grid-row: 1 / 2;
  }

  .home-intro-feature-image {
    grid-column: 1 / 7;
    grid-row: 2 / 4;
  }

  .home-intro-text {
    grid-column: 8/13;
    grid-row: 2/3;
  }

  .home-intro-contact-button-link {
    grid-column: 8/13;
    grid-row: 3/4;
  }

  .home-about-me-image {
    grid-column: 8 / 13;
    grid-row: 1 / 3;
    min-width: 20rem;
  }

  .home-about-me-content {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }

  .home-about-me-button-link {
    grid-column: 1/7;
    grid-row: 2/3;
  }

  .home-gallery {
    grid-template-rows: repeat(4, auto);
  }

  .home-gallery-text {
    grid-column: 1 / 13;
    grid-row: 1 / 2;
  }

  .home-image-slider {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .home-gallery-button-link {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
  }
}
